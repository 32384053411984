// doctorService.js

import axios from 'axios';

const fetchVendors = async (token) => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/vendors`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        throw new Error('Failed to fetch vendors information');
    }

    return response.data.data;
};

const fetchQr = async (token, code) => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/vendors/search/${code}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        throw new Error('Failed to fetch QR information');
    }

    return response.data.data;
};

export { fetchVendors, fetchQr };
