import React, { Fragment, useState, useEffect  } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from "react-datepicker";
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
// Image
import logo from "../../../assets/images/logo-white.png";
import img2 from "../../../assets/images/login/2.png";

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/setting/registrationSlice';
// Swiper css
import 'swiper/css';
import 'swiper/css/pagination';

const RegisterPage = () => {
  const { register, handleSubmit, setValue, formState: { errors }, control } = useForm();
  const [phone, setPhone] = useState('');
  const [apiError, setApiError] = useState('');

  const [vendorObj, setVendor] = useState(null);

  const navigate = useNavigate(); // Use navigate hook
  const dispatch = useDispatch();

  const { param } = useParams();

  useEffect(() => {
    if (param) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/vendors/search/${param}`)
        .then(response => {
          if (response.data.data) {
            setVendor(response.data.data)
          } else {
            navigate('/sign-up/6thlanedentistry');
          }

        })
        .catch(error => {
          console.error('Error fetching vendor data:', error);
        });
    }
  }, [navigate, param]);


  const validatePhone = (value) => {
    const cleanedValue = value.replace(/\D/g, '');



    if (cleanedValue.length >= 10 && cleanedValue.startsWith('94')) {
      return true;
    }
    return 'Invalid mobile number. It should start with the country code and be at least 10 digits long.';
  };


  const handlePhoneChange = (value) => {
    setPhone(value);
    setValue('mobile', value);
  };

  const mutation = useMutation((data) => {

    data.vendorId = vendorObj.id;
    return axios.post(`${process.env.REACT_APP_BASE_URL}/health/sms`, data);
  }, {
    onSuccess: (data) => {
      dispatch(setUser(data));
      navigate('/sign-in'); // Navigate to SMS verification page on success
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.error) {
        setApiError(error.response.data.error.message);
      } else {
        setApiError('An unexpected error occurred. Please try again later.');
      }
    }
  });

  const onSubmit = (data) => {
    setApiError(''); // Clear any previous error messages
    mutation.mutate(data);
  };

  return (
    <Fragment>
      <section className="sign-in-page">
        <Container className='sign-in-page-bg mt-5 mb-md-5 mb-0 p-0'>
          <Row className="no-gutters">
            <Col md='5' className="text-center">
              <div className="sign-in-detail">
                <Link className="sign-in-logo mb-5" to="/"><img src={logo} className="img-fluid" alt="logo" /></Link>
                <Swiper className="owl-carousel" loop={true} autoplay={true} spaceBetween={60} modules={[Pagination, Autoplay]}>
                  {vendorObj ?
                    <SwiperSlide className="item">
                      <img src={vendorObj?.img} className="img-fluid mb-4" alt="logo" />
                      <h4 className="mb-1">{vendorObj?.head}</h4>
                      <p>{vendorObj?.cms}</p>
                    </SwiperSlide>
                    : null}
                  <SwiperSlide className="item">
                    <img src={img2} className="img-fluid mb-4" alt="logo" />
                    <h4 className="mb-1">WHAT IS AYUBO DOCTOR?</h4>
                    <p>Exclusive global network of premium healthcare service providers</p>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
            <Col md='7' className="position-relative">
              <div className="sign-in-from">
                <h1 className="mb-0">User registration - "{vendorObj?.name}"</h1>
                <ul class="list-unstyled d-flex justify-content-between mt-3 small">
                  <li><Link to="/sign-in">Have an account? Login</Link></li>
                  <li> <Link to="/recover-password">Forgot password</Link></li>
                </ul>
                <h4 className='h4sizesignin'>Create an account</h4>
                <p>You can easily sign up with your mobile number & email.
                  Submit this form to SMS a temporary password to your mobile.
                  Enter it to complete registration.
                </p>
                <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="exampleInputEmail1" className="my-2">Name</Form.Label>
                    <Form.Control type="text" className="form-control mb-0" id="exampleInputEmail1" placeholder="Your name without initials eg: John Smith" {...register('name', { required: 'Name is required' })} />
                    {errors.name && <p className="error-message">{errors.name.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="mobile" className="my-2">Mobile</Form.Label>
                    <PhoneInput
                      country={'lk'}
                      value={phone}
                      onChange={handlePhoneChange}
                      inputClass="form-control mb-0"
                      containerStyle={{ width: '100%' }}
                      inputStyle={{ width: '100%' }}
                    />
                    <input
                      type="hidden"
                      {...register('mobile', {
                        required: 'Mobile number is required',
                        validate: validatePhone
                      })}
                    />
                    {errors.mobile && <p className="error-message">{errors.mobile.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="exampleInputEmail2" className="my-2">Email </Form.Label>
                    <Form.Control
                      type="email"
                      className={`form-control mb-0 ${errors.email ? 'is-invalid' : ''}`}
                      placeholder="eg: johnsmith@gmail.com"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: 'Invalid email address'
                        }
                      })}
                    />
                    {errors.email && <p className="error-message">{errors.email.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="dob" className="my-2" style={{ width: '100%' }}>Date of birth</Form.Label>
                    <div style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="dob"
                        rules={{ required: 'Date of birth is required' }}
                        render={({ field }) => (
                          <div style={{ width: '100%' }}>
                            <DatePicker
                              selected={field.value}
                              onChange={field.onChange}
                              maxDate={new Date()} // Disable future dates
                              className="form-control mb-0"
                              wrapperClassName="w-100"
                              showYearDropdown // Enable year dropdown
                              dropdownMode="select" // Use a select dropdown for the year
                              placeholderText="Select date of birth" // Placeholder text
                              style={{ width: '100%' }}
                            />
                          </div>
                        )}
                      />
                    </div>
                    {errors.dob && <p className="error-message">{errors.dob.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="exampleInputEmail2" className="my-2">Gender</Form.Label>
                    <Form.Select {...register('gender', { required: 'Gender is required' })}>
                      <option value="" selected>Please Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                    {errors.gender && <p className="error-message">{errors.gender.message}</p>}
                  </Form.Group>

                  <div className="d-flex justify-content-between w-100 align-items-center mt-2">
                    <Button variant='primary' type="submit" className="btn btn-primary float-end">
                      Submit
                    </Button>
                  </div>
                </Form>
                {apiError && <p className="error-message">{apiError}</p>}
                {mutation.isLoading && <p>Submitting...</p>}
                {/* {mutation.isError && <p>Error: {mutation.error.message}</p>} */}
                {mutation.isSuccess && <p>Submitted successfully!</p>}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default RegisterPage;
