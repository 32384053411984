import React, { Fragment } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { fetchPatients } from '../../../api/api'; // Adjust the path as necessary
import { useQuery } from 'react-query';

const AllPatient = () => {

   const user = useSelector((state) => state.auth.userInfo);

   console.log(user , 99999)

   const vendorId = user.id
   const { data, error, isLoading } = useQuery(['patients', vendorId], () => fetchPatients(vendorId), {
      enabled: !!vendorId,
    });


    console.log(data , 9999)
  
    if (isLoading) return <p>Loading...</p>;
  
    if (error) return <p>Error: {error.message}</p>;





   return (
      <Fragment>
         <Row>
            <Col sm='12'>
               <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                     <div className="iq-header-title">
                        <h4 className="card-title">All Patient</h4>
                     </div>
                  </div>
                  <div className="iq-card-body">
                     <div className="table-responsive">
                        <Table id="datatable" className="" bordered striped >
                           <thead>
                              <tr>
                                 <th>Name</th>
                                 <th>Email</th>
                                 <th>Phone</th>
                                 <th>Gender</th>
                                 <th>Date of Birth</th>
                                 <th>Join Date</th>
                              </tr>
                           </thead>
                           <tbody>

                           {data.data && data.data.length > 0 ? (
                      data.data.map(patient => (
                        <tr key={patient.id}>
                          <td>{patient.firstName}</td>
                          <td>{patient.email}</td>
                          <td>{patient.phone}</td>
                          <td>{patient.gender}</td>
                          <td>{patient.dob}</td>
                          <td>{patient.createdAt}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No patients found.</td>
                      </tr>
                    )}


                             


                           </tbody>
                           <tfoot>
                              <tr>
                              <th>Name</th>
                                 <th>Email</th>
                                 <th>Phone</th>
                                 <th>Gender</th>
                                 <th>Date of Birth</th>
                                 <th>Join Date</th>
                              </tr>
                           </tfoot>
                        </Table>
                     </div>
                  </div>
               </div>
            </Col>
         </Row>

      </Fragment>
   )
}

export default AllPatient
