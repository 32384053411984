import React, { Fragment, useState } from 'react';
import { Col, Row, Table, FormControl, Button, Modal, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { fetchPatientsByDoctorAndVendor } from '../../../api/api'; // Adjust the path as necessary
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Function to format date to d-m-y
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const DoctorPatient = () => {
  const user = useSelector((state) => state.auth.userInfo);

  // Extract vendorId from URL params
  const { vendorId } = useParams();

  const doctorId = user.id;

  // State for search query and modal
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [note, setNote] = useState('');

  // Fetch patients data
  const { data, error, isLoading } = useQuery(
    ['patients', doctorId, vendorId],
    () => fetchPatientsByDoctorAndVendor(doctorId, vendorId),
    {
      enabled: !!doctorId && !!vendorId, // Ensure both IDs are available
    }
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle note input change
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  // Handle opening the modal
  const handleShowModal = (patient) => {
    setSelectedPatient(patient);
    setShowModal(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPatient(null);
    setNote('');
  };

  // Handle note submission
  const handleSubmitNote = () => {
    // Logic to submit note (e.g., API call) goes here
    console.log('Submitting note:', note, 'for patient:', selectedPatient);
    handleCloseModal();
  };

  // Filter data based on search query
  const filteredPatients = data?.data?.filter(patient => {
    const fullName = `${patient.user.firstName} ${patient.user.lastName}`.toLowerCase();
    return (
      fullName.includes(searchQuery.toLowerCase()) ||
      patient.user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      patient.user.phone.includes(searchQuery)
    );
  }) || [];

  if (isLoading) return <p>Loading...</p>;

  if (error) return <p>Error: {error.message}</p>;

  return (
    <Fragment>
      <Row>
        <Col sm='12'>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">My Patients</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="mb-3">
                <FormControl
                  type="text"
                  placeholder="Search by name, email, or phone"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="table-responsive">
                <Table id="datatable" bordered striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>Date of Birth</th>
                      <th>Join Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPatients.length > 0 ? (
                      filteredPatients.map(patient => (
                        <tr key={patient.id}>
                          <td>{patient.user.firstName}</td>
                          <td>{patient.user.email}</td>
                          <td>{patient.user.phone}</td>
                          <td>{patient.user.gender}</td>
                          <td>{formatDate(patient.user.dob)}</td>
                          <td>{formatDate(patient.user.createdAt)}</td>
                          <td>
                            <Button variant="primary" onClick={() => handleShowModal(patient)}>
                              Add Note
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No patients found.</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>Date of Birth</th>
                      <th>Join Date</th>
                      <th>Actions</th>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Modal for adding notes */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note for {selectedPatient?.user.firstName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="noteTextarea">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={note}
                onChange={handleNoteChange}
                placeholder="Enter your note here..."
                className="mb-3" // Add margin bottom for spacing
              />
              <Button variant="primary" onClick={handleSubmitNote}>
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default DoctorPatient;
