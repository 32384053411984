import React, { Fragment, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from 'react-query';
import QRCodeSVG from 'qrcode.react';
import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';
import { useSelector } from "react-redux";
import { fetchQr } from './doctorService';

const Qr = () => {
  const user = useSelector((state) => state.auth.userInfo);

  const fetchQrData = async () => {
    const token = user.accessToken; // Assuming you have the token in the user object
    return fetchQr(token, user.code);
  };

  const { data: vendor, isLoading, isError, error } = useQuery(['vendor', user.code], fetchQrData);

  const qrCodeRefApp = useRef(null);
  const qrCodeRefSite = useRef(null);

  const handleDownloadQRCode = async (ref, fileName) => {
    const node = ref.current;
    const dataUrl = await toPng(node);
    saveAs(dataUrl, `${fileName}.png`);
  };

  if (isLoading) return <p>Loading...</p>;

  if (isError) return <p>Error: {error.message}</p>;

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">QR Codes</h4>
              </div>
            </div>
          </div>
        </Col>
        {vendor && (
          <>
            <Col xs={12} sm={6} md={3}>
              <div className="iq-card">
                <div className="iq-card-body text-center">
                  <div className="doc-profile">
                    <img
                      className="rounded-circle img-fluid avatar-140"
                      src={vendor.img}
                      alt="profile"
                    />
                  </div>
                  <div className="iq-doc-info mt-3">
                    <h4>User registration</h4>
                  </div>
                  <div className="mt-3 qr-code-container" ref={qrCodeRefApp}>
                    <QRCodeSVG value={`https://www.ayubodoctor.com/sign-up/${vendor.code}`} size={250} />
                  </div>
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={() => handleDownloadQRCode(qrCodeRefApp, `${vendor.name}-app-qrcode`)}
                  >
                    Download 
                  </button>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <div className="iq-card">
                <div className="iq-card-body text-center">
                  <div className="doc-profile">
                    <img
                      className="rounded-circle img-fluid avatar-140"
                      src={vendor.img}
                      alt="profile"
                    />
                  </div>
                  <div className="iq-doc-info mt-3">
                    <h4>http://www.mothernbabycare.com</h4>
                  </div>
                  <div className="mt-3 qr-code-container" ref={qrCodeRefSite}>
                    <QRCodeSVG value={`https://www.mothernbabycare.com`} size={250} />
                  </div>
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={() => handleDownloadQRCode(qrCodeRefSite, `${vendor.name}-site-qrcode`)}
                  >
                    Download 
                  </button>
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Fragment>
  );
};

export default Qr;
