import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import fetchUser from '../../../api/fetchUser';
import { setTokens, setUserInfo } from '../../../store/setting/authSlice';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';

// Image imports
import logo from "../../../assets/images/logo-white.png";
import img from "../../../assets/images/login/1.png";
import img1 from "../../../assets/images/login/2.png";
import img2 from "../../../assets/images/login/3.png";

// Function to handle form submission
const loginUser = async (loginData) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/sign-in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(loginData)
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return response.json();
};

const LoginPage = () => {
  // Initialize react-hook-form
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Initialize state for password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Initialize react-query mutation
  const mutation = useMutation(loginUser, {
    onSuccess: async (data) => {
      const { accessToken, refreshToken } = data.data;
      dispatch(setTokens({ accessToken, refreshToken }));

      const userInfo = await fetchUser(data.data.accessToken);

      dispatch(setUserInfo(userInfo.data));


      if (process.env.REACT_APP_BASE_ADMIN === userInfo.data.roles[0]) {
        navigate('/admin');
      } else if(process.env.REACT_APP_BASE_VENDOR === userInfo.data.roles[0]){
        navigate('/vendor');
      }else if(process.env.REACT_APP_BASE_DOCTOR === userInfo.data.roles[0]){
        navigate('/doctor');
      }else{
        navigate('/dashboard');
      }


  },
    onError: (error) => {
      console.error('Error during login:', error);
      // Handle login error (e.g., display error message to the user)
    }
  });

// Form submission handler
const onSubmit = (data) => {
  mutation.mutate(data);
};

// Toggle password visibility
const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};

return (
  <section className="sign-in-page">
    <Container className="sign-in-page-bg mt-5 mb-md-5 mb-0 p-0">
      <Row className="row no-gutters">
        <Col md='6' className="text-center">
          <div className="sign-in-detail">
            <Link className="sign-in-logo" to="/"><img src={logo} className="img-fluid" alt="logo" /></Link>
            <p>Exclusive global network of premium healthcare service providers</p>
            <Swiper
              className="owl-carousel"
              autoplay={{ delay: 3000 }}
              loop={true}
              modules={[Pagination, Autoplay]}
              spaceBetween={30}
            >
              <SwiperSlide className="item">
                <img src={img} className="img-fluid mb-4" alt="logo" />
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={img1} className="img-fluid mb-4" alt="logo" />
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={img2} className="img-fluid mb-4" alt="logo" />
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              </SwiperSlide>
            </Swiper>
          </div>
        </Col>
        <Col md='6' className="position-relative">
          <div className="sign-in-from">
            <h1 className="mb-0">Sign in</h1>
            <p>Enter your email address and password to access admin panel.</p>
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className='form-group'>
                {/* <Form.Label htmlFor="email" className="mb-2">Email address</Form.Label> */}
                <Form.Control
                  type="email"
                  className="form-control mb-0"
                  id="email"
                  placeholder="Email"
                  {...register('email', { required: 'Email is required' })}
                />
                {errors.email && <span className="text-danger">{errors.email.message}</span>}
              </Form.Group>
              <Form.Group className="form-group">
                {/* <Form.Label htmlFor="password" className='mb-2'>Password</Form.Label> */}
                <div className="input-group">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    className="form-control mb-0"
                    id="password"
                    placeholder="Password"
                    {...register('password', { required: 'Password is required' })}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </div>
                {errors.password && <span className="text-danger">{errors.password.message}</span>}
              </Form.Group>
              <div className="d-flex w-100 justify-content-between align-items-center mt-3 w-100">
                <div className="custom-control mt-2 pt-1">
                  <Link to="/recover-password">Forgot password</Link>
                </div>
                <Button type="submit" className="btn btn-primary float-end" disabled={mutation.isLoading}>
                  {mutation.isLoading ? 'Signing in...' : 'Sign in'}
                </Button>
              </div>
            </Form>
            {mutation.isError && <div className="text-danger mt-2">{mutation.error.message}</div>}
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
};

export default LoginPage;
