import React, { useState, useContext, memo, Fragment } from "react";

// Router
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// React-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

// Components
import SidebarMenu from "./sidebar-menu";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo(() => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("");

  const user = useSelector((state) => state.auth.userInfo);

  return (
    <Fragment>
      <nav className="iq-sidebar-menu">
        <Accordion as="ul" className="iq-menu">
          <li className="iq-menu-title">
            <i className="ri-subtract-line"></i>
            <span>Dashboard</span>
          </li>
          {process.env.REACT_APP_BASE_ADMIN === user?.roles[0] ? (
            <SidebarMenu isTag="true" pathname="/admin" title="My Dashboard">
              <i className="ri-hospital-fill"></i>
            </SidebarMenu>
          ) : process.env.REACT_APP_BASE_VENDOR === user?.roles[0] ? (
            <SidebarMenu isTag="true" pathname="/vendor" title="My Dashboard">
              <i className="ri-hospital-fill"></i>
            </SidebarMenu>
          ) : (
            <SidebarMenu isTag="true" pathname="/dashboard" title="My Dashboard">
              <i className="ri-hospital-fill"></i>
            </SidebarMenu>
          )}

          {process.env.REACT_APP_BASE_ADMIN === user?.roles[0] ? (
            <>
              <li className="iq-menu-title">
                <i className="ri-subtract-line"></i>
                <span>User Management</span>
              </li>
              <Accordion.Item
                as="li"
                eventKey="doctor-menu"
                bsPrefix={`nav-item ${active === "doctor" ? "active" : ""}`}
                onClick={() => setActive("doctor")}
              >
                <CustomToggle
                  eventKey="doctor-menu"
                  onClick={(activeKey) => setActiveMenu(activeKey)}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Vendors</Tooltip>}
                  >
                    <i className="ri-user-3-fill"></i>
                  </OverlayTrigger>
                  <span className="item-name">Vendors</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                </CustomToggle>
                <Accordion.Collapse eventKey="doctor-menu">
                  <ul className="iq-submenu collapse menu-open">
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-file-list-fill"
                      pathname="/all-vendors"
                      title="All Vendors"
                    ></SidebarMenu>
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-user-add-fill"
                      pathname="/add-doctors"
                      title="Add Vendors"
                    ></SidebarMenu>
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-profile-fill"
                      pathname="/doctors-profile"
                      title="Doctors Profile"
                    ></SidebarMenu>
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-file-edit-fill"
                      pathname="/edit-doctors"
                      title="Edit Doctors"
                    ></SidebarMenu>
                  </ul>
                </Accordion.Collapse>
              </Accordion.Item>
            </>
          ) : null}

          {(process.env.REACT_APP_BASE_VENDOR === user?.roles[0] ||
            process.env.REACT_APP_BASE_DOCTOR === user?.roles[0]) && (
            <>
              <li className="iq-menu-title">
                <i className="ri-subtract-line"></i>
                <span>Patient</span>
              </li>
              <Accordion.Item
                as="li"
                eventKey="patient-menu"
                bsPrefix={`nav-item ${active === "patient" ? "active" : ""}`}
                onClick={() => setActive("patient")}
              >
                <CustomToggle
                  eventKey="patient-menu"
                  onClick={(activeKey) => setActiveMenu(activeKey)}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>My Patient</Tooltip>}
                  >
                    <i className="ri-user-3-fill"></i>
                  </OverlayTrigger>
                  <span className="item-name">My Patient</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                </CustomToggle>
                <Accordion.Collapse eventKey="patient-menu">
                  <ul className="iq-submenu collapse menu-open">
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-file-list-fill"
                      pathname="/all-patient"
                      title="All Patient"
                    ></SidebarMenu>
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-user-add-fill"
                      pathname="/add-patient"
                      title="Add Patient"
                    ></SidebarMenu>
                  </ul>
                </Accordion.Collapse>
              </Accordion.Item>
            </>
          )}

          {process.env.REACT_APP_BASE_VENDOR === user?.roles[0] && (
            <>
              <li className="iq-menu-title">
                <i className="ri-subtract-line"></i>
                <span>Doctors</span>
              </li>
              <Accordion.Item
                as="li"
                eventKey="doctors-menu"
                bsPrefix={`nav-item ${active === "doctors" ? "active" : ""}`}
                onClick={() => setActive("doctors")}
              >
                <CustomToggle
                  eventKey="doctors-menu"
                  onClick={(activeKey) => setActiveMenu(activeKey)}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>My Doctors</Tooltip>}
                  >
                    <i className="ri-user-3-fill"></i>
                  </OverlayTrigger>
                  <span className="item-name">My Doctors</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                </CustomToggle>
                <Accordion.Collapse eventKey="doctors-menu">
                  <ul className="iq-submenu collapse menu-open">
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-file-list-fill"
                      pathname="/all-doctors"
                      title="All Doctors"
                    ></SidebarMenu>
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-user-add-fill"
                      pathname="/add-doctors"
                      title="Add Doctor"
                    ></SidebarMenu>
                  </ul>
                </Accordion.Collapse>
              </Accordion.Item>

              <Accordion.Item
                as="li"
                eventKey="qr-menu"
                bsPrefix={`nav-item ${active === "qr" ? "active" : ""}`}
                onClick={() => setActive("qr")}
              >
                <CustomToggle
                  eventKey="qr-menu"
                  onClick={(activeKey) => setActiveMenu(activeKey)}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>QR codes</Tooltip>}
                  >
                    <i className="ri-qr-code-line"></i>
                  </OverlayTrigger>
                  <span className="item-name">QR codes</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                </CustomToggle>
                <Accordion.Collapse eventKey="qr-menu">
                  <ul className="iq-submenu collapse menu-open">
                    <SidebarMenu
                      isTag="false"
                      staticIcon="true"
                      iconClass="ri-file-list-fill"
                      pathname="/qr"
                      title="View"
                    ></SidebarMenu>
                  </ul>
                </Accordion.Collapse>
              </Accordion.Item>
            </>
          )}
        </Accordion>
      </nav>
      <div className="p-3"></div>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
