import React, { Fragment, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from 'react-query';
import QRCodeSVG from 'qrcode.react';
import { Link } from "react-router-dom";
import {  fetchVendors } from './vendorService';

import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';

const AllVendors = () => {
  const { data: doctors, isLoading, isError, error } = useQuery('doctors', fetchVendors);

  const qrCodeRefs = useRef([]);

  const handleDownloadQRCode = async (index, doctor) => {
    const node = qrCodeRefs.current[index];
    const dataUrl = await toPng(node);
    saveAs(dataUrl, `${doctor.name}-qrcode.png`);
  };

  if (isLoading) return <p>Loading...</p>;

  if (isError) return <p>Error: {error.message}</p>;

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Vendors list</h4>
              </div>
            </div>
          </div>
        </Col>
        {doctors.map((doctor, index) => (
          <Col key={doctor.id} sm="6" md="3">
            <div className="iq-card">
              <div className="iq-card-body text-center">
                <div className="doc-profile">
                  <img
                    className="rounded-circle img-fluid avatar-140"
                    src={doctor.img}
                    alt="profile"
                  />
                </div>
                <div className="iq-doc-info mt-3">
                  <h4>{doctor.name}</h4>
                  <p className="mb-0">{doctor.head}</p>
                </div>
                
                {/* <Link to={`/doctor/${doctor.id}`} className="btn btn-primary">
                  View Profile
                </Link> */}
                <div className="mt-3" ref={(el) => qrCodeRefs.current[index] = el}>
                  <QRCodeSVG value={`https://www.ayubodoctor.com/sign-up/${doctor.code}`} size={250}  />
                </div>
                <button
                  className="btn btn-secondary mt-2"
                  onClick={() => handleDownloadQRCode(index, doctor)}
                >
                  Download QR Code
                </button>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default AllVendors;
