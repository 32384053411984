// CSS imports
import './assets/css/bootstrap.min.css';
import './assets/css/typography.css';
import './assets/css/style.css';
import './assets/css/style-rtl.css';
import './assets/css/responsive.css';
import './assets/fullcalendar/core/main.css';
import './assets/fullcalendar/daygrid/main.css';
import './assets/fullcalendar/timegrid/main.css';
import './assets/fullcalendar/list/main.css';
import './assets/css/flatpickr.min.css';
import 'choices.js/public/assets/styles/choices.min.css';
import 'flatpickr/dist/flatpickr.css';
import 'react-notifications/lib/notifications.css';



// React and Redux imports
import React from 'react';
import { useDispatch } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import queryClient from './queryClient';

// Redux action import
import { setSetting } from './store/setting/actions';

function App(props) {
  const dispatch = useDispatch();

  // Dispatch a Redux action
  React.useEffect(() => {
    dispatch(setSetting());
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        {props.children}
      </div>
    </QueryClientProvider>
  );
}

export default App;
