import React, { Fragment, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import QRCode from "qrcode.react";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import img from "../assets/images/user/11.png";

function calculateAge(dob) {
  const dobDate = new Date(dob);
  const today = new Date();
  let years = today.getFullYear() - dobDate.getFullYear();
  let months = today.getMonth() - dobDate.getMonth();
  if (months < 0 || (months === 0 && today.getDate() < dobDate.getDate())) {
    years--;
    months += 12;
  }
  if (today.getDate() < dobDate.getDate()) {
    months--;
  }
  return { years, months };
}

const DashboardThree = () => {
  const user = useSelector((state) => state.auth.userInfo);
  const doctors = user.vendors;

  const qrCodeRefs = useRef([]);

  const handleDownloadQRCode = async (index, doctor) => {
    const node = qrCodeRefs.current[index];
    const dataUrl = await toPng(node);
    saveAs(dataUrl, `${doctor.name}-qrcode.png`);
  };

  const age = calculateAge(user.dob);

  return (
    <Fragment>
      <Row>
        <Col lg="4">
          <div className="iq-card iq-user-profile-block">
            <div className="iq-card-body">
              <div className="user-details-block">
                <div className="user-profile text-center">
                  <img
                    src={img}
                    alt="profile-img"
                    className="avatar-130 img-fluid"
                  />
                </div>
                <div className="text-center mt-3">
                  <h4>
                    <b>{user.firstName}</b>
                  </h4>
                  <p>
                    {age.years} years, {age.months} months
                  </p>
                </div>
             
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">My Places</h4>
              </div>
            </div>
          </div>
        </Col>
        {doctors.map((doctor, index) => (
          <Col key={doctor.vendor.id} sm="6" md="3">
            <div className="iq-card">
              <div className="iq-card-body text-center">
                <div className="doc-profile">
                  <img
                    className="rounded-circle img-fluid avatar-140"
                    src={doctor.vendor.img}
                    alt="profile"
                  />
                </div>
                <div className="iq-doc-info mt-3">
                  <h4>{doctor.vendor.name}</h4>
                  <p className="mb-0">{doctor.vendor.head}</p>
                </div>
                <div className="mt-3" ref={(el) => (qrCodeRefs.current[index] = el)}>
                  <QRCode
                    value={`https://www.6thlanedentistry.com`}
                    size={256} // Increase size for better resolution
                    renderAs="canvas" // Render as canvas
                    bgColor="#FFFFFF" // White background
                    fgColor="#000000" // Black foreground
                    level="H" // High error correction level
                    style={{
                      width: "100%",
                      maxWidth: "256px", // Prevent it from becoming too large
                      height: "auto",
                    }}
                  />
                </div>
                <button
                  className="btn btn-secondary mt-2"
                  onClick={() => handleDownloadQRCode(index, doctor.vendor)}
                >
                  Download QR Code
                </button>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default DashboardThree;
