import axiosInstance from '../../../api/axiosInstance';

// Function to fetch doctors
const fetchDoctors = async ({ name, mobile }) => {
    const params = new URLSearchParams();
    if (name) params.append('name', name);
    if (mobile) params.append('mobile', mobile);

    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/users/search/doctors?${params.toString()}`);

    if (response.status !== 200) {
        throw new Error('Failed to fetch doctors information');
    }

    return response.data.data;
};

// Function to add a doctor-vendor relationship
const addDoctorVendor = async (userId, vendorId) => {
    const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/users/add-doctor-vendor`, 
    {
        userId, 
        vendorId,
    });

    if (response.status !== 201) {
        throw new Error('Failed to add doctor-vendor relationship');
    }

    return response.data.data;
};

// Function to fetch patients
const fetchPatients = async ({ name, mobile, doctorId }) => {
    const params = new URLSearchParams();
    if (name) params.append('name', name);
    if (mobile) params.append('mobile', mobile);
    if (doctorId) params.append('doctorId', doctorId);

    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/users/search/patients?${params.toString()}`);

    if (response.status !== 200) {
        throw new Error('Failed to fetch patients information');
    }

    return response.data.data;
};

// Function to add a patient to a doctor
const addPatientToDoctor = async (userId, doctorId) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BASE_URL}/users/add-patient-to-doctor`, 
      {
        userId,
        doctorId,
      }
    );

    if (response.status !== 201) {
        throw new Error('Failed to add patient to doctor');
    }

    return response.data.data;
};

export { fetchDoctors, addDoctorVendor, fetchPatients, addPatientToDoctor };
