import React, { Fragment, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { fetchPatients, addPatientToDoctor } from './patientService'; // Import the functions

// Images (placeholders for actual patient images)
import male from "../../../assets/images/user/12.jpg";
import female from "../../../assets/images/user/15.jpg";

const AddPatient = () => {
  const { handleSubmit, control, watch, formState: { errors } } = useForm();
  const [patients, setPatients] = useState([]);

  const user = useSelector((state) => state.auth.userInfo);
  const selectedDoctorId = user.id; // Assuming selectedDoctorId is the doctorId

  const { mutateAsync: fetchPatientsMutation } = useMutation(fetchPatients);
  const { mutateAsync: addPatientToDoctorMutation } = useMutation(addPatientToDoctor);

  const onSubmit = async (data) => {
    NotificationManager.info('Please Wait');
    try {
      const result = await fetchPatientsMutation({ ...data, doctorId: selectedDoctorId }); // Pass doctorId
      setPatients(result);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const addPatientToDoctorFun = async (patientId, doctorId) => {
    console.log("Adding patient to doctor with doctorId:", doctorId); // Debugging line
   
    try {
      NotificationManager.info('Please Wait');
      await addPatientToDoctorMutation({ userId: patientId, doctorId });
      const result = await fetchPatientsMutation({
        name: watchName,
        mobile: watchMobile,
        doctorId: selectedDoctorId, // Pass doctorId
      });
      setPatients(result);
      NotificationManager.success('Patient added to doctor successfully');
    } catch (error) {
      console.error("Error adding patient to doctor:", error);
    }
  };
  
  const watchName = watch("name");
  const watchMobile = watch("mobile");

  return (
    <Fragment>
      {/* Form for searching patients */}
      <Row>
        <Col lg='12'>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Search Patient</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="new-user-info">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    {/* Name Input */}
                    <Form.Group className="col-md-6">
                      <Form.Label className="mb-0" htmlFor="fname">Name:</Form.Label>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                          validate: value => {
                            if (!value && !watchMobile) {
                              return "Either Name or Mobile is required";
                            }
                          }
                        }}
                        render={({ field }) => (
                          <Form.Control
                            type="text"
                            className={`form-control my-2 ${errors.name ? 'is-invalid' : ''}`}
                            id="fname"
                            placeholder="Name"
                            {...field}
                          />
                        )}
                      />
                      {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                    </Form.Group>

                    {/* Mobile Input */}
                    <Form.Group className="col-md-6">
                      <Form.Label className="mb-0" htmlFor="mobile">Mobile:</Form.Label>
                      <Controller
                        name="mobile"
                        control={control}
                        defaultValue=""
                        rules={{
                          validate: value => {
                            if (!value && !watchName) {
                              return "Either Name or Mobile is required";
                            }
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Invalid mobile number"
                          }
                        }}
                        render={({ field }) => (
                          <Form.Control
                            type="text"
                            className={`form-control my-2 ${errors.mobile ? 'is-invalid' : ''}`}
                            id="mobile"
                            placeholder="Mobile"
                            {...field}
                          />
                        )}
                      />
                      {errors.mobile && <div className="invalid-feedback">{errors.mobile.message}</div>}
                    </Form.Group>
                  </Row>
                  <button type="submit" className="btn btn-primary">Search</button>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Patients List */}
      <Row>
        <Col sm="12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Patients List</h4>
              </div>
            </div>
            <div className="iq-card-body">
              {patients.length === 0 ? (
                <p>No patients found</p>
              ) : (
                <Row>
                  {patients.map((patient, index) => (
                    <Col sm="6" md="3" key={index}>
                      <div className="iq-card">
                        <div className="iq-card-body text-center">
                          <div className="doc-profile">
                            <img
                              className="rounded-circle img-fluid avatar-80"
                              src={patient.gender === 'Male' ? male : female} // Fallback to a default image if none provided
                              alt="profile"
                            />
                          </div>
                          <div className="iq-doc-info mt-3">
                            <h4>{patient.firstName} {patient.lastName}</h4>
                            <p className="mb-0">
                              {patient.specializations && patient.specializations.length > 0
                                ? patient.specializations.join(', ')
                                : null
                              }
                            </p>
                            <p className="mb-0">Email: {patient.email || 'No email available'}</p>
                            <p className="mb-0">Phone: {patient.phone || 'No phone available'}</p>
                            <p className="mb-0">Date of Birth: {patient.dob ? new Date(patient.dob).toLocaleDateString() : 'No Date of Birth available'}</p>

                            <button
                              className="btn btn-primary mt-2"
                              onClick={() => addPatientToDoctorFun(patient.id, selectedDoctorId)}
                            >
                              Add to Doctor
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <NotificationContainer />
    </Fragment>
  );
};

export default AddPatient;
