import ConfirmMail from "../views/modules/authentication/confirm-mail";
import LockScreen from "../views/modules/authentication/lock-screen";
import LoginPage from "../views/modules/authentication/login";
import RecoverPassword from "../views/modules/authentication/recover-password";
import Sms from "../views/modules/authentication/sms";

import RegisterPage from "../views/modules/authentication/register";
import RegisterDoctorPage from "../views/modules/authentication/register-doctor";


import ProfilePage from "../views/modules/authentication/profilePage";


export const AuthRouter = [
    {
        path:'/sign-in',
        element:<LoginPage/>
    },
    {
        path:'/profile',
        element:<ProfilePage/>
    },
    {
        path:'/sign-up/:param',
        element:<RegisterPage/>
    },
    {
        path:'/sign-up-doctor',
        element:<RegisterDoctorPage/>
    },
    {
        path:'/sms',
        element:<Sms/>
    },
    {
        path:'/recover-password',
        element:<RecoverPassword/>
    },
    {
        path:'/confirm-mail',
        element:<ConfirmMail/>
    },
    {
        path:'/lock-screen',
        element:<LockScreen/>
    }
]