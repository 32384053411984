import { createStore, applyMiddleware } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import settingReducer from './setting/reducers';
import registrationReducer from './setting/registrationSlice';
import authReducer from './setting/authSlice';

// Root reducer
const rootReducer = {
  setting: settingReducer,
  registration: persistReducer({ key: 'registration', storage }, registrationReducer),
  auth: persistReducer({ key: 'auth', storage }, authReducer),
};

// Custom middleware setup
const customizedMiddleware = getDefaultMiddleware({
  thunk, // Include thunk middleware
  serializableCheck: {
    ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
    ignoredActionPaths: ['register', 'rehydrate'],
    ignoredPaths: ['items.dates'],
  },
});

// Configure store with root reducer and middleware
export const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

// Create persistor for persisting state
export const persistor = persistStore(store);
