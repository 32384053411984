// src/api/axiosInstance.js
import axios from 'axios';
import { setTokens } from '../store/setting/authSlice'; // Adjust the import path as necessary
import { store } from '../store'; // Adjust the import path as necessary

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Request interceptor for adding Authorization header
axiosInstance.interceptors.request.use(
  async (config) => {
    const { auth } = store.getState();
    const { accessToken } = auth || {}; // Ensure auth exists

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling token refresh
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { auth } = store.getState();
        const { refreshToken } = auth || {}; // Ensure auth exists

        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/token/refresh`, { refreshToken });

        const { accessToken, refreshToken: newRefreshToken } = response.data.data;

        // Update tokens in the store
        store.dispatch(setTokens({ accessToken, refreshToken: newRefreshToken }));

        // Update the headers for the new access token
        axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;

        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      } catch (err) {
        console.error('Refresh token failed:', err);

        // Optionally: Redirect to login or handle the failure appropriately
        store.dispatch(setTokens({ accessToken: null, refreshToken: null }));

        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
