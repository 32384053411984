// signout.js
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { clearAuth } from '../store/setting/authSlice';

const useSignout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const signout = () => {
    // Perform any other signout-related logic here, such as removing tokens from local storage
    // For example:
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('refreshToken');

    // Dispatch the clearAuth action to reset the state
    dispatch(clearAuth());
    navigate('/sign-in');
  };

  return signout;
};

export default useSignout;
