import React, { Fragment  , useState} from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
import logo from "../../../assets/images/logo-white.png";
import img1 from "../../../assets/images/login/1.png";
import img2 from "../../../assets/images/login/2.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';


const Sms = () => {
  const [apiError, setApiError] = useState('');

  const { register, handleSubmit, formState: { errors } } = useForm();
  const user = useSelector((state) => state.registration.user?.data?.data);
  
  const navigate = useNavigate(); // Use navigate hook
  //const dispatch = useDispatch();

  const mutation = useMutation((data) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/health/verify`, data); 
  }, {
    onSuccess: (data) => {
      //dispatch(setUser(data));
      navigate('/confirm-mail'); // Navigate to SMS verification page on success
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.error) {
        setApiError(error.response.data.error.message);
      } else {
        setApiError('An unexpected error occurred. Please try again later.');
      }
    }
  });

  const onSubmit = (data) => {
    mutation.mutate({ ...data, ...user });
  };

  return (
    <Fragment>
      <section className="sign-in-page">
        <Container className='sign-in-page-bg mt-5 mb-md-5 mb-0 p-0'>
          <Row className="no-gutters">
            <Col md='5' className="text-center">
              <div className="sign-in-detail">
                <Link className="sign-in-logo mb-5" to="/"><img src={logo} className="img-fluid" alt="logo" /></Link>
                <Swiper className="owl-carousel" loop={true} autoplay={true} spaceBetween={30} modules={[Pagination, Autoplay]}>
                  <SwiperSlide className="item">
                    <img src={img1} className="img-fluid mb-4" alt="logo" />
                    <h4 className="mb-1">DENTISTRY, COSMETIC & WELLNESS</h4>
                    <p>Your trusted state of the art center for affordable dentistry, cosmetic surgery and wellness, providing high quality care under international standards!</p>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <img src={img2} className="img-fluid mb-4" alt="logo" />
                    <h4 className="mb-1">WHAT IS AYUBO DOCTOR?</h4>
                    <p>It is a global network of exclusive premium healthcare service providers.</p>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
            <Col md='7' className="position-relative">
              <div className="sign-in-from">
                <h1 className="mb-0">Verify your mobile</h1>
                <p>We have sent 4 digit OTP (one time password) via SMS to your mobile number</p>
                <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="otp" className="my-2">OTP</Form.Label>
                    <Form.Control type="text" className="form-control mb-0" id="otp" placeholder="Enter OTP" {...register('otp', { required: 'OTP is required' })} />
                    {errors.otp && <p className="error-message">{errors.otp.message}</p>}
                  </Form.Group>
                  <div className="d-flex justify-content-between w-100 align-items-center mt-2">
                    <Button variant='primary' type="submit" className="btn btn-primary float-end">
                      Submit
                    </Button>
                  </div>
                </Form>
                {apiError && <p className="error-message">{apiError}</p>}

                {mutation.isLoading && <p>Submitting...</p>}
                {mutation.isSuccess && <p>Submitted successfully!</p>}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Sms;
