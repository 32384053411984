import axiosInstance from './axiosInstance';

const fetchUser = async () => {
  const response = await axiosInstance.get('/users/me');

  if (response.status !== 200) {
    throw new Error('Failed to fetch user information');
  }

  return response.data;
};

export default fetchUser;