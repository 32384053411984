import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchDoctorsByVendorId } from './doctorService'; // Import the function to fetch doctors by vendor ID

// Images (placeholders for actual doctor images)
import male from "../../../assets/images/user/12.jpg";
import female from "../../../assets/images/user/15.jpg";
import { useSelector } from 'react-redux';

const AllDoctors = () => {
  const [doctors, setDoctors] = useState([]);

  const user = useSelector((state) => state.auth.userInfo);

  const vendorId = user.id;





  useEffect(() => {
    const getDoctors = async () => {
      try {
        const result = await fetchDoctorsByVendorId(vendorId);
        setDoctors(result);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    getDoctors();
  }, [vendorId]);

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Doctors List</h4>
              </div>
            </div>
            <div className="iq-card-body">
              {doctors.length === 0 ? (
                <p>No doctors found</p>
              ) : (
                <Row>
                  {doctors.map((doctor, index) => (
                    <Col sm="6" md="3" key={index}>
                      <div className="iq-card">
                        <div className="iq-card-body text-center">
                          <div className="doc-profile">
                            <img
                              className="rounded-circle img-fluid avatar-80"
                              src={doctor.gender === 'Male' ? male : female} // Fallback to a default image if none provided
                              alt="profile"
                            />
                          </div>
                          <div className="iq-doc-info mt-3">
                            <h4>Dr. {doctor.firstName} {doctor.lastName}</h4>
                            <p className="mb-0">
                              {doctor.specializations && doctor.specializations.length > 0
                                ? doctor.specializations.join(', ')
                                : "No specializations available"
                              }
                            </p>
                            <p className="mb-0">Email: {doctor.email || 'No email available'}</p>
                            <p className="mb-0">Phone: {doctor.phone || 'No phone available'}</p>
                            <p className="mb-0">Registration Number: {doctor.registrationNumber || 'No Registration Number available'}</p>
                            <Link to="#" className="btn btn-primary mt-2">
                              View Patient
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllDoctors;
