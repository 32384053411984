import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Image imports
import logo from "../../../assets/images/logo-white.png";
import img from "../../../assets/images/login/1.png";
import img1 from "../../../assets/images/login/2.png";
import img2 from "../../../assets/images/login/3.png";

// Video URLs
const videoThumbnails = [
  { id: 1, videoUrl: "https://www.youtube.com/embed/dQw4w9WgXcQ" },
  { id: 2, videoUrl: "https://www.youtube.com/embed/tgbNymZ7vqY" },
  { id: 3, videoUrl: "https://www.youtube.com/embed/3JZ_D3ELwOQ" },
  { id: 4, videoUrl: "https://www.youtube.com/embed/4pDHzBk6ceM" },
  { id: 5, videoUrl: "https://www.youtube.com/embed/9bZkp7q19f0" },
  { id: 6, videoUrl: "https://www.youtube.com/embed/I2d8UOLlE2U" },
  { id: 7, videoUrl: "https://www.youtube.com/embed/vhMsp4b_Hl8" },
  { id: 8, videoUrl: "https://www.youtube.com/embed/5MgBikgcWnY" }
];

const ProfilePage = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleNext = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoThumbnails.length);
  };

  const handlePrev = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + videoThumbnails.length) % videoThumbnails.length);
  };

  return (
    <section className="profile-page">
      <Container className="profile-page-bg mt-5 mb-md-5 mb-0 p-0">
        <Row className="row no-gutters">
          <Col md='6' className="text-center">
            <div className="profile-detail">
              <Link className="profile-logo" to="/">
                <img src={logo} className="img-fluid small-logo" alt="logo" />
              </Link>
              <h1 className="mb-4">Welcome to Your Pregnancy Journey</h1>
              <p className="profile-highlight">
                Experience a fulfilling pregnancy journey with our exclusive resources and support. From managing appointments to accessing expert advice, we provide everything you need for a healthy and happy pregnancy.
              </p>
            </div>
          </Col>
          <Col md='6'>
            <div className="profile-content">
              <h2 className="section-title">Gallery</h2>
              <Swiper
                autoplay={{ delay: 3000 }}
                loop={true}
                modules={[Pagination, Autoplay, Navigation]}
                spaceBetween={20}
                slidesPerView={2}
                className="gallery-slider"
              >
                <SwiperSlide>
                  <img src={img} className="img-fluid gallery-img" alt="gallery" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={img1} className="img-fluid gallery-img" alt="gallery" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={img2} className="img-fluid gallery-img" alt="gallery" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={img} className="img-fluid gallery-img" alt="gallery" />
                </SwiperSlide>
              </Swiper>
              <h2 className="section-title mt-5">Video Highlights</h2>
              <div className="video-container mt-4">
                <iframe
                  width="100%"
                  height="315"
                  src={videoThumbnails[currentVideoIndex].videoUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="video-navigation mt-4 text-center">
                <Button variant="primary" onClick={handlePrev} className="navigation-btn">&larr; Previous</Button>
                <Button variant="primary" onClick={handleNext} className="navigation-btn ms-3">Next &rarr;</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProfilePage;
