import React, { Fragment } from 'react';
import { Button, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import axiosInstance from '../../../api/axiosInstance'; // Import the Axios instance
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  store } from '../../../store'; // Adjust the import path as necessary

// Image
import user from "../../../assets/images/user/11.png";
import { Link } from 'react-router-dom';
import fetchUser from '../../../api/fetchUser';

const EditDoctors =  () => {

   
   

   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      watch
   } = useForm({
      defaultValues: {
         firstName: "Bini",
         lastName: "Jets",
         userName: "Bini@01",
         city: "Atlanta",
         gender: "Male",
         dateOfBirth: "1984-01-24",
         maritalStatus: "Single",
         age: "33-45",
         country: "USA",
         state: "Georgia",
         address: "37 Cardinal Lane\nPetersburg, VA 23803\nUnited States of America\nZip Code: 85001",
         currentPassword: "",
         newPassword: "",
         verifyPassword: "",
         emailNotification: true,
         smsNotification: true,
         contactNumber: "001 2536 123 458",
         email: "Binijone@demo.com",
         url: "https://getbootstrap.com"
      }
   });

   const onSubmit = async (data) => {
      if (data.newPassword && data.verifyPassword && data.newPassword !== data.verifyPassword) {
         return console.error("Passwords do not match");
      }

      try {
         // Change Password API Request
         await axiosInstance.patch('/users/change-password', {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword
         });

         // Handle successful password change (e.g., show a success message, reset form)
         console.log("Password changed successfully");

         toast.success('Password changed successfully');


         reset();
      } catch (error) {
         // Handle errors (e.g., show an error message)

       
       //  toast.error(error.details.newPassword);
      }
   };


   return (
      <Fragment>
         <Tab.Container defaultActiveKey={'personal-information'}>
            <Row>
               <Col lg='12'>
                  <div className="iq-card">
                     <div className="iq-card-body p-0">
                        <div className="iq-edit-list">
                           <Nav as={'ul'} className="iq-edit-profile nav nav-pills list-inline mb-0 flex-md-row flex-column">
                              <Nav.Item as={'li'} className="col-md-3 p-0">
                                 <Nav.Link className="nav-link" data-bs-toggle="pill" eventKey="personal-information">
                                    Personal Information
                                 </Nav.Link>
                              </Nav.Item>
                              <Nav.Item as={'li'} className="col-md-3 p-0">
                                 <Nav.Link className="nav-link" data-bs-toggle="pill" eventKey="chang-pwd">
                                    Change Password
                                 </Nav.Link>
                              </Nav.Item>
                              <Nav.Item as={'li'} className="col-md-3 p-0">
                                 <Nav.Link className="nav-link" data-bs-toggle="pill" eventKey="emailandsms">
                                    Email and SMS
                                 </Nav.Link>
                              </Nav.Item>
                              <Nav.Item as={'li'} className="col-md-3 p-0">
                                 <Nav.Link className="nav-link" data-bs-toggle="pill" eventKey="manage-contact">
                                    Manage Contact
                                 </Nav.Link>
                              </Nav.Item>
                           </Nav>
                        </div>
                     </div>
                  </div>
               </Col>
               <Col lg='12'>
                  <div className="iq-edit-list-data">
                     <Tab.Content>
                        {/* Personal Information Form */}
                        <Tab.Pane className="fade show" eventKey="personal-information" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-header d-flex justify-content-between">
                                 <div className="iq-header-title">
                                    <h4 className="card-title">Personal Information</h4>
                                 </div>
                              </div>
                              <div className="iq-card-body">
                                 <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="align-items-center">
                                       <Form.Group className="form-group col-sm-6">
                                          <Form.Label className='mb-0' htmlFor="fname">First Name:</Form.Label>
                                          <Form.Control
                                             type="text"
                                             className="my-2"
                                             id="fname"
                                             {...register("firstName", { required: "First name is required" })}
                                          />
                                          {errors.firstName && <p className="text-danger">{errors.firstName.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <Form.Label className='mb-0' htmlFor="lname">Last Name:</Form.Label>
                                          <Form.Control
                                             type="text"
                                             className="my-2"
                                             id="lname"
                                             {...register("lastName", { required: "Last name is required" })}
                                          />
                                          {errors.lastName && <p className="text-danger">{errors.lastName.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <Form.Label className='mb-0' htmlFor="uname">User Name:</Form.Label>
                                          <Form.Control
                                             type="text"
                                             className="my-2"
                                             id="uname"
                                             {...register("userName", { required: "Username is required" })}
                                          />
                                          {errors.userName && <p className="text-danger">{errors.userName.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <Form.Label className='mb-0' htmlFor="cname">City:</Form.Label>
                                          <Form.Control
                                             type="text"
                                             className="my-2"
                                             id="cname"
                                             {...register("city", { required: "City is required" })}
                                          />
                                          {errors.city && <p className="text-danger">{errors.city.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <label className="d-block">Gender:</label>
                                          <div className="custom-control custom-radio d-inline-flex me-3">
                                             <Form.Check.Input
                                                type="radio"
                                                id="customRadio6"
                                                value="Male"
                                                {...register("gender", { required: "Gender is required" })}
                                                defaultChecked
                                             />
                                             <Form.Label className="custom-control-label mb-0" htmlFor="customRadio6"> Male </Form.Label>
                                          </div>
                                          <div className="custom-control custom-radio d-inline-flex me-3">
                                             <Form.Check.Input
                                                type="radio"
                                                id="customRadio7"
                                                value="Female"
                                                {...register("gender")}
                                             />
                                             <Form.Label className="custom-control-label mb-0" htmlFor="customRadio7"> Female </Form.Label>
                                          </div>
                                          {errors.gender && <p className="text-danger">{errors.gender.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <Form.Label className='mb-0' htmlFor="dob">Date Of Birth:</Form.Label>
                                          <Form.Control
                                             type="date"
                                             className="my-2"
                                             id="dob"
                                             {...register("dateOfBirth", { required: "Date of birth is required" })}
                                          />
                                          {errors.dateOfBirth && <p className="text-danger">{errors.dateOfBirth.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <Form.Label className='mb-0'>Marital Status:</Form.Label>
                                          <Form.Select
                                             className="my-2"
                                             {...register("maritalStatus", { required: "Marital status is required" })}
                                          >
                                             <option value="Single">Single</option>
                                             <option value="Married">Married</option>
                                             <option value="Widowed">Widowed</option>
                                             <option value="Divorced">Divorced</option>
                                             <option value="Separated">Separated</option>
                                          </Form.Select>
                                          {errors.maritalStatus && <p className="text-danger">{errors.maritalStatus.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <Form.Label className='mb-0'>Age:</Form.Label>
                                          <Form.Select
                                             className="my-2"
                                             {...register("age", { required: "Age range is required" })}
                                          >
                                             <option value="12-18">12-18</option>
                                             <option value="19-32">19-32</option>
                                             <option value="33-45">33-45</option>
                                             <option value="46-62">46-62</option>
                                             <option value="63+">63+</option>
                                          </Form.Select>
                                          {errors.age && <p className="text-danger">{errors.age.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <label>Country:</label>
                                          <Form.Select
                                             className="my-2"
                                             {...register("country", { required: "Country is required" })}
                                          >
                                             <option value="Canada">Canada</option>
                                             <option value="Noida">Noida</option>
                                             <option value="USA">USA</option>
                                             <option value="India">India</option>
                                             <option value="Africa">Africa</option>
                                          </Form.Select>
                                          {errors.country && <p className="text-danger">{errors.country.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-6">
                                          <label>State:</label>
                                          <Form.Select
                                             className="my-2"
                                             {...register("state", { required: "State is required" })}
                                          >
                                             <option value="California">California</option>
                                             <option value="Florida">Florida</option>
                                             <option value="Georgia">Georgia</option>
                                             <option value="Connecticut">Connecticut</option>
                                             <option value="Louisiana">Louisiana</option>
                                          </Form.Select>
                                          {errors.state && <p className="text-danger">{errors.state.message}</p>}
                                       </Form.Group>
                                       <Form.Group className="form-group col-sm-12">
                                          <Form.Label className='mb-0' htmlFor="address">Address:</Form.Label>
                                          <Form.Control
                                             as="textarea"
                                             className="my-2"
                                             id="address"
                                             rows={5}
                                             {...register("address", { required: "Address is required" })}
                                          />
                                          {errors.address && <p className="text-danger">{errors.address.message}</p>}
                                       </Form.Group>
                                    </Row>
                                    <Button type="submit" variant="btn btn-primary">Submit</Button>
                                 </Form>
                              </div>
                           </div>
                        </Tab.Pane>

                        {/* Change Password Form */}
                        <Tab.Pane className="fade show" eventKey="chang-pwd" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-header d-flex justify-content-between">
                                 <div className="iq-header-title">
                                    <h4 className="card-title">Change Password</h4>
                                 </div>
                              </div>
                              <div className="iq-card-body">
                                 <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="cpass">Current Password:</Form.Label>
                                       <Link to="#" className="float-end">Forgot Password</Link>
                                       <Form.Control
                                          type="password"
                                          className="my-2"
                                          id="cpass"
                                          {...register("currentPassword", { required: "Current password is required" })}
                                       />
                                       {errors.currentPassword && <p className="text-danger">{errors.currentPassword.message}</p>}
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="npass">New Password:</Form.Label>
                                       <Form.Control
                                          type="password"
                                          className="my-2"
                                          id="npass"
                                          {...register("newPassword", { required: "New password is required" })}
                                       />
                                       {errors.newPassword && <p className="text-danger">{errors.newPassword.message}</p>}
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="vpass">Verify Password:</Form.Label>
                                       <Form.Control
                                          type="password"
                                          className="my-2"
                                          id="vpass"
                                          {...register("verifyPassword", {
                                             required: "Please confirm your password",
                                             validate: (value) =>
                                                value === watch("newPassword") || "Passwords do not match"
                                          })}
                                       />
                                       {errors.verifyPassword && <p className="text-danger">{errors.verifyPassword.message}</p>}
                                    </Form.Group>
                                    <Button type="submit" variant="btn btn-primary">Submit</Button>
                                 </Form>
                              </div>
                           </div>
                        </Tab.Pane>

                        {/* Other Tabs */}
                        <Tab.Pane className="fade" eventKey="emailandsms" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-header d-flex justify-content-between">
                                 <div className="iq-header-title">
                                    <h4 className="card-title">Email and SMS</h4>
                                 </div>
                              </div>
                              <div className="iq-card-body">
                                 <Form>
                                    <Form.Group className="form-group row align-items-center">
                                       <label className="col-md-3" htmlFor="emailnotification">Email Notification:</label>
                                       <div className="col-md-9 custom-control custom-switch">
                                          <Form.Check.Input
                                             type="checkbox"
                                             id="emailnotification"
                                             {...register("emailNotification")}
                                          />
                                       </div>
                                    </Form.Group>
                                    <Form.Group className="form-group row align-items-center">
                                       <label className="col-md-3" htmlFor="smsnotification">SMS Notification:</label>
                                       <div className="col-md-9 custom-control custom-switch">
                                          <Form.Check.Input
                                             type="checkbox"
                                             id="smsnotification"
                                             {...register("smsNotification")}
                                          />
                                       </div>
                                    </Form.Group>
                                    <Button type="submit" variant="btn btn-primary">Submit</Button>
                                 </Form>
                              </div>
                           </div>
                        </Tab.Pane>
                        <Tab.Pane className="fade" eventKey="manage-contact" role="tabpanel">
                           <div className="iq-card">
                              <div className="iq-card-header d-flex justify-content-between">
                                 <div className="iq-header-title">
                                    <h4 className="card-title">Manage Contact</h4>
                                 </div>
                              </div>
                              <div className="iq-card-body">
                                 <Form>
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="cno">Contact Number:</Form.Label>
                                       <Form.Control
                                          type="text"
                                          className="my-2"
                                          id="cno"
                                          {...register("contactNumber", { required: "Contact number is required" })}
                                       />
                                       {errors.contactNumber && <p className="text-danger">{errors.contactNumber.message}</p>}
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="email">Email:</Form.Label>
                                       <Form.Control
                                          type="email"
                                          className="my-2"
                                          id="email"
                                          {...register("email", {
                                             required: "Email is required",
                                             pattern: {
                                                value: /^\S+@\S+$/i,
                                                message: "Invalid email format"
                                             }
                                          })}
                                       />
                                       {errors.email && <p className="text-danger">{errors.email.message}</p>}
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                       <Form.Label htmlFor="url">Url:</Form.Label>
                                       <Form.Control
                                          type="text"
                                          className="my-2"
                                          id="url"
                                          {...register("url", {
                                             required: "URL is required",
                                             pattern: {
                                                value: /^(ftp|http|https):\/\/[^ "]+$/,
                                                message: "Invalid URL format"
                                             }
                                          })}
                                       />
                                       {errors.url && <p className="text-danger">{errors.url.message}</p>}
                                    </Form.Group>
                                    <Button type="submit" variant="btn btn-primary">Submit</Button>
                                 </Form>
                              </div>
                           </div>
                        </Tab.Pane>
                     </Tab.Content>
                  </div>
               </Col>
            </Row>
         </Tab.Container>
         <ToastContainer />
      </Fragment>
   );
}

export default EditDoctors;
