import axiosInstance from '../../../api/axiosInstance';

// Existing functions

const fetchVendors = async (token) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/vendors`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        throw new Error('Failed to fetch vendors information');
    }

    return response.data.data;
};

const fetchQr = async (token, code) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/vendors/search/${code}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        throw new Error('Failed to fetch QR information');
    }

    return response.data.data;
};

const fetchDoctores = async ({ name, mobile }) => {
    const params = new URLSearchParams();
    if (name) params.append('name', name);
    if (mobile) params.append('mobile', mobile);

    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/users/search/doctors?${params.toString()}`);

    if (response.status !== 200) {
        throw new Error('Failed to fetch doctors information');
    }

    return response.data.data;
};

// New function to add a doctor-vendor relationship without token
const addDoctorVendor = async (userId, vendorId) => {
    const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/users/add-doctor-vendor`, 
    {
        userId, 
        vendorId,
    });

    if (response.status !== 201) {
        throw new Error('Failed to add doctor-vendor relationship');
    }

    return response.data.data;
};

// New function to get doctors by vendor ID without token
const fetchDoctorsByVendorId = async (vendorId) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/users/vendor/${vendorId}/doctors`);

    if (response.status !== 200) {
        throw new Error('Failed to fetch doctors by vendor ID');
    }

    return response.data.data;
};

export { fetchVendors, fetchQr, fetchDoctores, addDoctorVendor, fetchDoctorsByVendorId };
