import axiosInstance from './axiosInstance';

// Function to fetch patients by vendorId
const fetchPatients = async (vendorId) => {
  const response = await axiosInstance.get(`/users/vendor/${vendorId}`);

  if (response.status !== 200) {
    throw new Error('Failed to fetch user information');
  }

  return response.data;
};

// Function to fetch patients by doctorId and vendorId
const fetchPatientsByDoctorAndVendor = async (doctorId, vendorId) => {
  const response = await axiosInstance.get(`/users/doctor/${doctorId}/vendor/${vendorId}/patients`);

  if (response.status !== 200) {
    throw new Error('Failed to fetch patients information');
  }

  return response.data;
};

export { fetchPatients, fetchPatientsByDoctorAndVendor };
