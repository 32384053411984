import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: null,
    userInfo: null,
  },
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    clearAuth: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.userInfo = null;
    },
  },
});

export const { setTokens, setUserInfo, clearAuth } = authSlice.actions;

// Selector to check if the user is authenticated
export const selectIsAuthenticated = (state) => !!state.auth.accessToken;

// Selector to get the access token
export const getToken = (state) => state.auth.accessToken;

// Selector to get the refresh token
export const getRefreshToken = (state) => state.auth.refreshToken;

export default authSlice.reducer;
