import React, { Fragment, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from "react-datepicker";
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';  // Import react-select

// Image
import logo from "../../../assets/images/logo-white.png";
import img2 from "../../../assets/images/login/2.png";

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/setting/registrationSlice';
// Swiper css
import 'swiper/css';
import 'swiper/css/pagination';

const specializationsOptions = [
  { value: 'Bariatric Surgeon', label: 'Bariatric Surgeon' },
  { value: 'Cardiologist', label: 'Cardiologist' },
  { value: 'Cardiothoracic Surgeon', label: 'Cardiothoracic Surgeon' },
  { value: 'Colorectal Surgeon', label: 'Colorectal Surgeon' },
  { value: 'Cosmetic Dentist', label: 'Cosmetic Dentist' },
  { value: 'Dental Surgeon', label: 'Dental Surgeon' },
  { value: 'Dentist', label: 'Dentist' },
  { value: 'Doctor', label: 'Doctor' },
  { value: 'Dermatologist', label: 'Dermatologist' },
  { value: 'Endocrine Surgeon', label: 'Endocrine Surgeon' },
  { value: 'Endocrinologist', label: 'Endocrinologist' },
  { value: 'Endodontist', label: 'Endodontist' },
  { value: 'Emergency Medicine Physician', label: 'Emergency Medicine Physician' },
  { value: 'Family Medicine Physician', label: 'Family Medicine Physician' },
  { value: 'Gastroenterologist', label: 'Gastroenterologist' },
  { value: 'Gastrointestinal Surgeon', label: 'Gastrointestinal Surgeon' },
  { value: 'General Dental Practitioner', label: 'General Dental Practitioner' },
  { value: 'General Practitioner', label: 'General Practitioner' },
  { value: 'General Surgeon', label: 'General Surgeon' },
  { value: 'Hepatobiliary Surgeon', label: 'Hepatobiliary Surgeon' },
  { value: 'Hematologist', label: 'Hematologist' },
  { value: 'Infectious Disease Specialist', label: 'Infectious Disease Specialist' },
  { value: 'Internist', label: 'Internist' },
  { value: 'Maxillofacial Surgeon', label: 'Maxillofacial Surgeon' },
  { value: 'Neurologist', label: 'Neurologist' },
  { value: 'Neuro Physician', label: 'Neuro Physician' },
  { value: 'Onco Surgeon', label: 'Onco Surgeon' },
  { value: 'Oncologist', label: 'Oncologist' },
  { value: 'Ophthalmologist', label: 'Ophthalmologist' },
  { value: 'Oral Pathologist', label: 'Oral Pathologist' },
  { value: 'Orthopedic Surgeon', label: 'Orthopedic Surgeon' },
  { value: 'Orthodontist', label: 'Orthodontist' },
  { value: 'Pathologist', label: 'Pathologist' },
  { value: 'Pediatric Dentist', label: 'Pediatric Dentist' },
  { value: 'Pediatrician', label: 'Pediatrician' },
  { value: 'Pediatric Surgeon', label: 'Pediatric Surgeon' },
  { value: 'Plastic Surgeon', label: 'Plastic Surgeon' },
  { value: 'Psychiatrist', label: 'Psychiatrist' },
  { value: 'Pulmonologist', label: 'Pulmonologist' },
  { value: 'Radiologist', label: 'Radiologist' },
  { value: 'Rehabilitation Physician', label: 'Rehabilitation Physician' },
  { value: 'Restorative Dentist', label: 'Restorative Dentist' },
  { value: 'Rheumatologist', label: 'Rheumatologist' },
  { value: 'Sports Medicine', label: 'Sports Medicine' },
  { value: 'Thoracic Surgeon', label: 'Thoracic Surgeon' },
  { value: 'Transplant Surgeon', label: 'Transplant Surgeon' },
  { value: 'Trauma Surgeon', label: 'Trauma Surgeon' },
  { value: 'Urological Surgeon', label: 'Urological Surgeon' },
  { value: 'Vascular Surgeon', label: 'Vascular Surgeon' },
  { value: 'Virologist', label: 'Virologist' },
];

const RegisterDoctorPage = () => {
  const { register, handleSubmit, setValue, formState: { errors }, control } = useForm();
  const [phone, setPhone] = useState('');
  const [apiError, setApiError] = useState('');

  const navigate = useNavigate(); 
  const dispatch = useDispatch();

  const validatePhone = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue.length >= 10 && cleanedValue.startsWith('94')) {
      return true;
    }
    return 'Invalid mobile number. It should start with the country code and be at least 10 digits long.';
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setValue('mobile', value);
  };

  const mutation = useMutation((data) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/health/register-doctor`, data);
  }, {
    onSuccess: (data) => {
      dispatch(setUser(data));
      navigate('/sign-in');
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.error) {
        setApiError(error.response.data.error.message);
      } else {
        setApiError('An unexpected error occurred. Please try again later.');
      }
    }
  });

  const onSubmit = (data) => {
    setApiError('');
    mutation.mutate(data);
  };

  return (
    <Fragment>
      <section className="sign-in-page">
        <Container className='sign-in-page-bg mt-5 mb-md-5 mb-0 p-0'>
          <Row className="no-gutters">
            <Col md='5' className="text-center">
              <div className="sign-in-detail">
                <Link className="sign-in-logo mb-5" to="/"><img src={logo} className="img-fluid" alt="logo" /></Link>
                <Swiper className="owl-carousel" loop={true} autoplay={true} spaceBetween={60} modules={[Pagination, Autoplay]}>
                  <SwiperSlide className="item">
                    <img src={img2} className="img-fluid mb-4" alt="logo" />
                    <h4 className="mb-1">WHAT IS AYUBO DOCTOR?</h4>
                    <p>Exclusive global network of premium healthcare service providers</p>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
            <Col md='7' className="position-relative">
              <div className="sign-in-from">
                <h1 className="mb-0">Doctor registration</h1>
                <ul className="list-unstyled d-flex justify-content-between mt-3 small">
                  <li><Link to="/sign-in">Have an account? Login</Link></li>
                  <li> <Link to="/recover-password">Forgot password</Link></li>
                </ul>
                <h4 className='h4sizesignin'>Create an account</h4>
                <p>You can easily sign up with your mobile number & email.
                  Submit this form to SMS a temporary password to your mobile.
                  Enter it to complete registration.
                </p>
                <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="exampleInputEmail1" className="my-2">Name</Form.Label>
                    <Form.Control type="text" className="form-control mb-0" id="exampleInputEmail1" placeholder="Your name without initials eg: John Smith" {...register('name', { required: 'Name is required' })} />
                    {errors.name && <p className="error-message">{errors.name.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="registrationNumber" className="my-2">Registration Number</Form.Label>
                    <Form.Control type="text" className="form-control mb-0" id="registrationNumber" placeholder="SLMC XXXXX" {...register('registrationNumber', { required: 'Registration Number is required' })} />
                    {errors.registrationNumber && <p className="error-message">{errors.registrationNumber.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="specializations" className="my-2">Specialization</Form.Label>
                    <Controller
                      name="specializations"
                      control={control}
                      rules={{ required: 'At least one specialization is required', validate: value => value.length <= 3 || 'You can select up to 3 specializations only' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={specializationsOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select specializations"
                        />
                      )}
                    />
                    {errors.specializations && <p className="error-message">{errors.specializations.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="mobile" className="my-2">Mobile</Form.Label>
                    <PhoneInput
                      country={'lk'}
                      value={phone}
                      onChange={handlePhoneChange}
                      inputClass="form-control mb-0"
                      containerStyle={{ width: '100%' }}
                      inputStyle={{ width: '100%' }}
                    />
                    <input
                      type="hidden"
                      {...register('mobile', {
                        required: 'Mobile number is required',
                        validate: validatePhone
                      })}
                    />
                    {errors.mobile && <p className="error-message">{errors.mobile.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="exampleInputEmail2" className="my-2">Email</Form.Label>
                    <Form.Control
                      type="email"
                      className={`form-control mb-0 ${errors.email ? 'is-invalid' : ''}`}
                      placeholder="eg: johnsmith@gmail.com"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: 'Invalid email address'
                        }
                      })}
                    />
                    {errors.email && <p className="error-message">{errors.email.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="dob" className="my-2" style={{ width: '100%' }}>Date of birth</Form.Label>
                    <div style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="dob"
                        rules={{ required: 'Date of birth is required' }}
                        render={({ field }) => (
                          <div style={{ width: '100%' }}>
                            <DatePicker
                              selected={field.value}
                              onChange={field.onChange}
                              maxDate={new Date()}
                              className="form-control mb-0"
                              wrapperClassName="w-100"
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="Select date of birth"
                              style={{ width: '100%' }}
                            />
                          </div>
                        )}
                      />
                    </div>
                    {errors.dob && <p className="error-message">{errors.dob.message}</p>}
                  </Form.Group>

                  <Form.Group className='form-group'>
                    <Form.Label htmlFor="exampleInputEmail2" className="my-2">Gender</Form.Label>
                    <Form.Select {...register('gender', { required: 'Gender is required' })}>
                      <option value="" selected>Please Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                    {errors.gender && <p className="error-message">{errors.gender.message}</p>}
                  </Form.Group>

                  <div className="d-flex justify-content-between w-100 align-items-center mt-2">
                    <Button variant='primary' type="submit" className="btn btn-primary float-end">
                      Submit
                    </Button>
                  </div>
                </Form>
                {apiError && <p className="error-message">{apiError}</p>}
                {mutation.isLoading && <p>Submitting...</p>}
                {mutation.isSuccess && <p>Submitted successfully!</p>}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default RegisterDoctorPage;
