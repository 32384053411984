import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = registrationSlice.actions;
export default registrationSlice.reducer;
